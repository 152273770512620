import React, { useEffect } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p><em>Last updated: 18.10.2024</em></p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Self Made Portraits. We are committed to protecting your personal information and your right to privacy. 
          If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, 
          please contact us at info@self-made-portraits.com.
        </p>
      </section>

      <section>
        <h2>2. What Information We Collect</h2>
        <p>We collect personal information that you voluntarily provide to us when you register, express an interest in obtaining information 
        about us or our products and services, or when you participate in activities on the site.</p>
        <p>This may include:</p>
        <ul>
          <li>Personal Identification Information: name, email address, phone number.</li>
          <li>Technical Information: IP address, browser type, operating system.</li>
          <li>Usage Information: pages visited, time spent, and interactions on our site.</li>
          <li>Payment Information (if applicable): billing details, payment information.</li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>To facilitate account creation and logon processes.</li>
          <li>To manage user accounts and provide customer support.</li>
          <li>To analyze user activity on the site to improve our services.</li>
          <li>To send marketing and promotional communications (only with your consent).</li>
        </ul>
      </section>

      <section>
        <h2>4. Sharing Your Information</h2>
        <p>We only share information with your consent, to comply with laws, to provide you with services, or to protect your rights. Third parties with access to your data include:</p>
        <ul>
          <li>Payment processors (e.g., Stripe) for transactions.</li>
          <li>Analytics services (e.g., Google Analytics) for usage analysis.</li>
          <li>Cloud hosting providers for secure data storage.</li>
        </ul>
      </section>

      <section>
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>We use cookies and similar tracking technologies to access or store information. You can learn more about how we use these technologies and how you can refuse certain cookies in our Cookie Policy.</p>
      </section>

      <section>
        <h2>6. Your Privacy Rights</h2>
        <p>In line with GDPR, you have the right to:</p>
        <ul>
          <li>Access: You can request access to the personal information we have about you.</li>
          <li>Rectification: You can update or correct your personal information.</li>
          <li>Erasure: You can request that we delete your data.</li>
          <li>Restrict Processing: You can limit the way we use your data.</li>
          <li>Data Portability: You can request a copy of your personal information.</li>
          <li>Object to Processing: You have the right to object to how we use your information, including for marketing purposes.</li>
        </ul>
      </section>

      <section>
        <h2>7. Security of Your Information</h2>
        <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure.</p>
      </section>

      <section>
        <h2>8. Data Retention</h2>
        <p>We will only retain your personal information for as long as necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law.</p>
      </section>

      <section>
        <h2>9. International Data Transfers</h2>
        <p>Your personal information may be transferred to, and processed in, countries other than your own. We will take all necessary measures to ensure your data is treated securely and in accordance with this privacy policy.</p>
      </section>

      <section>
        <h2>10. Updates to This Policy</h2>
        <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and will be effective as soon as it is accessible.</p>
      </section>

      <section>
        <h2>11. Contact Us</h2>
        <p>If you have questions or comments about this policy, you may contact us by email at info@self-made-portraits.com.</p>
      </section>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
